import { DisclaimerService } from '@core/services/disclaimer.service';
import { Profile } from '@model/profile';
import React, { useReducer } from 'react';

type Action =
  | { type: 'SET_PROFILE'; payload: { profile: Profile | null; passwordExpired: boolean | null } }
  | { type: 'SET_CONTACT_DISCLAIMER'; payload: boolean };
type Dispatch = (action: Action) => void;
type State = {
  profile: Profile | null;
  passwordExpired: boolean | null;
  isAcceptContactDisclaimer: boolean | null;
};

const ProfileStateContext = React.createContext<State | undefined>(undefined);
const ProfileDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_PROFILE': {
      return { ...state, ...action.payload };
    }
    case 'SET_CONTACT_DISCLAIMER': {
      return { ...state, isAcceptContactDisclaimer: action.payload };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface ProfileProviderProps {
  profile: Profile | null;
  passwordExpired: boolean | null;
  children: React.ReactNode;
}
const ProfileProvider: React.FC<ProfileProviderProps> = ({ profile, passwordExpired, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    profile,
    passwordExpired,
    isAcceptContactDisclaimer: DisclaimerService.getContact() || null,
  });

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>{children}</ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
};

const useProfileState = (): State => {
  const context = React.useContext(ProfileStateContext);
  if (context === undefined) {
    throw new Error('useProfileState must be used within a ProfileProvider');
  }
  return context;
};
const useProfileDispatch = (): Dispatch => {
  const context = React.useContext(ProfileDispatchContext);
  if (context === undefined) {
    throw new Error('useProfileDispatch must be used within a ProfileProvider');
  }
  return context;
};

export { ProfileProvider, useProfileState, useProfileDispatch };
