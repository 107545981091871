import React from 'react';
import { SecondaryButton } from '../secondary-button/SecondaryButton';
import { PrimaryButton } from '../primary-button/PrimaryButton';
import { DeleteButton } from '../delete-button/DeleteButton';
import { ButtonProps } from '@mis/sushi-tailwind-react';
import { Edit, Preview, Update, Save, Reserve } from '@containers/reserved-symbol/components/company-button';

export interface ListButtonItem extends ButtonProps {
  buttonStyle?: 'PRIMARY' | 'SECONDARY' | 'DELETE' | 'EDIT' | 'PREVIEW' | 'UPDATE' | 'SAVE' | 'RESERVE';
}

interface ListButtonProps {
  items: ListButtonItem[];
  baseProps?: ListButtonItem;
}

export const ListButton: React.FC<ListButtonProps> = ({ items, baseProps }) => {
  return (
    <>
      {items.map(({ buttonStyle, ...item }, index) => {
        if (buttonStyle) {
          if (buttonStyle === 'PRIMARY') {
            return <PrimaryButton key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'SECONDARY') {
            return <SecondaryButton key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'DELETE') {
            return <DeleteButton key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'EDIT') {
            return <Edit key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'PREVIEW') {
            return <Preview key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'UPDATE') {
            return <Update key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'SAVE') {
            return <Save key={index} {...baseProps} {...item} />;
          }
          if (buttonStyle === 'RESERVE') {
            return <Reserve key={index} {...baseProps} {...item} />;
          }
        } else {
          if (index === items.length - 1) {
            return <PrimaryButton key={index} {...baseProps} {...item} />;
          } else {
            return <SecondaryButton key={index} {...baseProps} {...item} />;
          }
        }
      })}
    </>
  );
};

export default ListButton;
