import { Spin } from '@mis/sushi-tailwind-react';
import React from 'react';

const PendingElement = (): React.ReactElement => {
  return (
    <div className="fixed inset-1/2">
      <Spin data-testid="pending-element" className="h-[36px] w-[36px]" />
    </div>
  );
};

export default PendingElement;
