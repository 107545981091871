interface ENV {
  name: string;
  classicDipoURL: string;
  webURL: string;
  apiURL: string;
  ssoURL: string;
  liveChatUrl: string;
  liveChatClientSecret: string;
  liveChatEnvName: string;
  maxRequest: number;
}

const pEnv = process.env as Record<string, string>;

export const env: ENV = {
  name: pEnv.REACT_APP_ENV_NAME,
  classicDipoURL: pEnv.REACT_APP_CLASSIC_DIPO_URL,
  webURL: pEnv.REACT_APP_WEB_URL,
  apiURL: pEnv.REACT_APP_API_URL,
  ssoURL: pEnv.REACT_APP_SSO_URL,
  liveChatUrl: pEnv.REACT_APP_LIVE_CHAT_URL,
  liveChatClientSecret: pEnv.REACT_APP_LIVE_CHAT_CLIENT_SECRET,
  liveChatEnvName: pEnv.REACT_APP_LIVE_CHAT_ENV_NAME,
  maxRequest: parseInt(pEnv.REACT_APP_MAX_REQUEST, 10) || 1,
};
