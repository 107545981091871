import { axios } from '@core/axios';
import { TypeOfCommentEnum } from '@model/enum/feedback.enum';
import { FeedbackFormType } from '@model/form/feedback.form';
import { FeedbackRequest } from '@model/request/feedback.response';
import { DIPOBackBaseResponse } from '@model/response/@base-response/dipo-back-base-response';
import { FeedbackDropdownTopicResponse } from '@model/response/feedback.response';
import { Observable, from, map } from 'rxjs';

export class FeedbackService {
  static basePath = '/feedback';

  public static createFeedback(data: FeedbackFormType): Observable<null[]> {
    const url = `${this.basePath}`;

    const request: FeedbackRequest = {
      anonymous: data.anonymous,
      fullName: data.anonymous ? '' : data.fullName,
      phoneNo: data.anonymous ? '' : data.phoneNo,
      email: data.anonymous ? '' : data.email || '',
      position: data.anonymous ? '' : data.position,
      company: data.anonymous ? '' : data.company,
      topic: data.topic,
      subTopic: data.subTopic,
      topicOther: data.topicOther,
      subTopicOther: data.subTopicOther,
      typeOfComment: data.typeOfComment as TypeOfCommentEnum,
      comment: data.comment,
    };
    const formData = new FormData();

    if (data.attachFile) {
      formData.append('attachFile', data.attachFile);
    }
    formData.append('request', JSON.stringify(request));
    return from(
      axios.post<DIPOBackBaseResponse<null>>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).pipe(map(({ data }) => data.data));
  }

  public static getDropdownFeedbackTopic(): Observable<FeedbackDropdownTopicResponse[]> {
    const url = `${this.basePath}/dropdown-topic`;
    return from(axios.get<DIPOBackBaseResponse<FeedbackDropdownTopicResponse>>(url)).pipe(map(({ data }) => data.data));
  }
}
