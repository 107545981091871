import { Spin } from '@mis/sushi-tailwind-react';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMatch } from '@tanstack/react-location';
const ErrorRouteFetch = (): React.ReactElement => {
  const match = useMatch();
  useEffect(() => {
    const { error } = match;
    if (error && (error as AxiosError).response) {
      const code = (error as AxiosError).response?.status;
      if (code === 401) {
        window.location.href = '/digitalipo';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <Spin />
    </div>
  );
};

export default ErrorRouteFetch;
