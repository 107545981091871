import React from 'react';
import FeedbackIcon from '@assets/images/feedback.svg';
import Tooltip from '@components/tooltip/Tooltip';
import detectClassNames from '@helpers/@utils/detectClassNames';

const FeedbackButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...props }) => {
  return (
    <Tooltip
      style={{ width: '14rem', background: 'white', color: '#212121' }}
      place="left"
      content="แสดงความคิดเห็นที่เกี่ยวข้องกับการระดมทุนและการเข้าจดทะเบียนในตลาดหลักทรัพย์ฯ"
    >
      <button className={detectClassNames('flex items-center justify-center', className)} {...props}>
        <img className="w-full" src={FeedbackIcon}></img>
      </button>
    </Tooltip>
  );
};

export default FeedbackButton;
