import { UserRole } from '@model/enum/user-role.enum';

export const FA_MEMBER_ROLES = [
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
];

export const SUPER_ADMIN_ROLES = [UserRole.ROLE_SUPER_ADMIN];

export const SEC_REGULATOR_ROLES = [UserRole.ROLE_SEC_OFFICER, UserRole.ROLE_SEC_DVP, UserRole.ROLE_SEC_VP];

export const SET_REGULATOR_ROLES = [
  UserRole.ROLE_SET_OFFICER,
  UserRole.ROLE_SET_DVP,
  UserRole.ROLE_SET_VP,
  UserRole.ROLE_SET_TSD,
  UserRole.ROLE_SET_MARKET,
  UserRole.ROLE_SET_MARKETING,
  UserRole.ROLE_SET_RM_OFFICER,
  UserRole.ROLE_SET_RM_DVP,
  UserRole.ROLE_SET_RM_VP,
  UserRole.ROLE_SET_RM_EVP,
  // Not related to the application
  UserRole.ROLE_SET_EVP,
  UserRole.ROLE_SET_PRESIDENT,
  UserRole.ROLE_SET_IT,
];

export const DASHBOARD_HIDE_TABS_ROLES = [
  UserRole.ROLE_SET_EVP,
  UserRole.ROLE_SET_PRESIDENT,
  UserRole.ROLE_SET_TSD,
  UserRole.ROLE_SET_MARKET,
  UserRole.ROLE_DIPO_ADMIN,
  UserRole.ROLE_SUPER_ADMIN,
];

export const DASHBOARD_TAB_SUBMITTING_FILING_ROLES = [
  // FA
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_UNDERWRITER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
  // SEC
  UserRole.ROLE_SEC_OFFICER,
  UserRole.ROLE_SEC_DVP,
  UserRole.ROLE_SEC_VP,
  // SET
  UserRole.ROLE_SET_OFFICER,
  UserRole.ROLE_SET_DVP,
  UserRole.ROLE_SET_VP,
  UserRole.ROLE_SET_RM_OFFICER,
  UserRole.ROLE_SET_RM_DVP,
  UserRole.ROLE_SET_RM_VP,
  UserRole.ROLE_SET_IT,
  UserRole.ROLE_SET_RM_EVP,
  UserRole.ROLE_SET_MARKETING,
];

export const DASHBOARD_TAB_APPLICATION_ROLES = [
  // FA
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
  // SEC
  UserRole.ROLE_SEC_OFFICER,
  UserRole.ROLE_SEC_DVP,
  UserRole.ROLE_SEC_VP,
  // SET
  UserRole.ROLE_SET_OFFICER,
  UserRole.ROLE_SET_DVP,
  UserRole.ROLE_SET_VP,
  UserRole.ROLE_SET_RM_OFFICER,
  UserRole.ROLE_SET_RM_DVP,
  UserRole.ROLE_SET_RM_VP,
];

export const DASHBOARD_TAB_RESERVED_SYMBOL_ROLES = [
  // FA
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
];

export const DASHBOARD_TAB_COMPLETE_ROLES = [
  // FA
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_UNDERWRITER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
  // SEC
  UserRole.ROLE_SEC_OFFICER,
  UserRole.ROLE_SEC_DVP,
  UserRole.ROLE_SEC_VP,
  // SET
  UserRole.ROLE_SET_OFFICER,
  UserRole.ROLE_SET_DVP,
  UserRole.ROLE_SET_VP,
  UserRole.ROLE_SET_RM_OFFICER,
  UserRole.ROLE_SET_RM_DVP,
  UserRole.ROLE_SET_RM_VP,
  UserRole.ROLE_SET_IT,
  UserRole.ROLE_SET_RM_EVP,
  UserRole.ROLE_SET_MARKETING,
];

export const DASHBOARD_TAB_CANCEL_ROLES = [
  // FA
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_UNDERWRITER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
  // SEC
  UserRole.ROLE_SEC_OFFICER,
  UserRole.ROLE_SEC_DVP,
  UserRole.ROLE_SEC_VP,
  // SET
  UserRole.ROLE_SET_OFFICER,
  UserRole.ROLE_SET_DVP,
  UserRole.ROLE_SET_VP,
  UserRole.ROLE_SET_RM_OFFICER,
  UserRole.ROLE_SET_RM_DVP,
  UserRole.ROLE_SET_RM_VP,
  UserRole.ROLE_SET_IT,
  UserRole.ROLE_SET_RM_EVP,
  UserRole.ROLE_SET_MARKETING,
];

export const DASHBOARD_TAB_ALL_ROLES = [
  // FA
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_UNDERWRITER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
  // SEC
  UserRole.ROLE_SEC_OFFICER,
  UserRole.ROLE_SEC_DVP,
  UserRole.ROLE_SEC_VP,
  // SET
  UserRole.ROLE_SET_OFFICER,
  UserRole.ROLE_SET_DVP,
  UserRole.ROLE_SET_VP,
  UserRole.ROLE_SET_RM_OFFICER,
  UserRole.ROLE_SET_RM_DVP,
  UserRole.ROLE_SET_RM_VP,
  UserRole.ROLE_SET_IT,
  UserRole.ROLE_SET_RM_EVP,
  UserRole.ROLE_SET_MARKETING,
];

export const VIEWER_ROLES = [UserRole.ROLE_SET_EVP, UserRole.ROLE_SET_RM_EVP, UserRole.ROLE_SET_MARKETING];
