import React from 'react';
import { Button, ButtonProps } from '@mis/sushi-tailwind-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Primary = styled(Button)`
  ${({ disabled, loading }) =>
    !disabled &&
    !loading &&
    css`
      background: linear-gradient(180deg, #ffcf36 0%, #fcb034 88.02%);
      box-shadow: none;
      :hover {
        background: #fcb034;
        box-shadow: none;
      }
      :active {
        background: #ffd000;
      }
    `}
`;

export const PrimaryButton: React.FC<ButtonProps> = (props) => {
  return <Primary type="button" data-testid="btnPrimary" {...props} />;
};
