import React from 'react';
import { FilterRoutesFn, Outlet, ReactLocation, Router } from '@tanstack/react-location';
import routers from 'router.config';
import PendingElement from '@components/pending-element/PendingElement';
import ErrorRouteFetch from '@components/error-route-fetch/ErrorRouteFetch';
import { rankRoutes } from '@tanstack/react-location-rank-routes';
import Feedback from '@components/feedback/Feedback';
import ScrollToTop from '@components/scroll-to-top/ScrollToTop';
import './globals';
import './App.css';
import ProviderConfiguration from '@components/provider-configuration/ProviderConfiguration';

const location = new ReactLocation();

const App: React.FC = () => {
  return (
    <ProviderConfiguration>
      <Router
        basepath="/digitalipo"
        location={location}
        filterRoutes={rankRoutes as FilterRoutesFn}
        routes={routers}
        defaultPendingElement={<PendingElement />}
        defaultErrorElement={<ErrorRouteFetch />}
        defaultPendingMs={100}
        defaultPendingMinMs={100}
      >
        <>
          <Outlet />
          <ScrollToTop />
        </>
      </Router>
      <Feedback />
    </ProviderConfiguration>
  );
};

export default App;
