import { ENGLISH_REGEX, THAI_REGEX } from '@constants/regex';

type HTMLElement = HTMLTextAreaElement | HTMLInputElement;

export function replaceAllowCharacter<T extends HTMLElement>(e: React.ChangeEvent<T>, allowOnly: 'en' | 'th') {
  if (allowOnly === 'en' && e.target.value.match(THAI_REGEX)) {
    e.target.value = e.target.value.replaceAll(THAI_REGEX, '');
  } else if (allowOnly === 'th' && e.target.value.match(ENGLISH_REGEX)) {
    e.target.value = e.target.value.replaceAll(ENGLISH_REGEX, '');
  }
  return e;
}
