import LiveChat from '@components/live-chat/LiveChat';
import React, { useReducer } from 'react';

type Action = { type: 'SET_INITIAL'; payload: boolean };
type Dispatch = (action: Action) => void;
type State = {
  initial: boolean;
};

const LiveChatStateContext = React.createContext<State | undefined>(undefined);
const LayoutDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_INITIAL':
      return { ...state, initial: action.payload };
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface InitialLiveChatProviderProps {
  children: React.ReactNode;
  initial: boolean;
}

const LiveChatProvider: React.FC<InitialLiveChatProviderProps> = ({ initial, children }) => {
  const [state, dispatch] = useReducer(reducer, { initial });
  return (
    <LiveChatStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
        <LiveChat />
      </LayoutDispatchContext.Provider>
    </LiveChatStateContext.Provider>
  );
};

const useLiveChatState = (): State => {
  const context = React.useContext(LiveChatStateContext);
  if (context === undefined) {
    throw new Error('useLiveChatState must be used within a LiveChatProvider');
  }
  return context;
};

const useLiveChatDispatch = (): Dispatch => {
  const context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLiveChatDispatch must be used within a LiveChatProvider');
  }
  return context;
};

export { LiveChatProvider, useLiveChatState, useLiveChatDispatch };
