import React from 'react';
import { Button, ButtonProps } from '@mis/sushi-tailwind-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Secondary = styled(Button)`
  ${({ disabled, loading }) =>
    !disabled &&
    !loading &&
    css`
      background: linear-gradient(180deg, #f2f2f3 0%, #e5e5e6 88.02%);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      :hover {
        box-shadow: 0 0 0 1px #fcb034;
      }
      :active {
        box-shadow: 0 0 0 1px #ffd200;
      }
    `}
`;

export const SecondaryButton: React.FC<ButtonProps> = (props) => {
  return <Secondary type="button" data-testid="btnSecondary" {...props} />;
};
