import React from 'react';
import { useLayoutDispatch } from '@context/Layout.context';
import { Icon, Notification as notification, NotificationProps as SushiNotificationProps } from '@mis/sushi-tailwind-react';

type IconType = 'SAVE' | 'ERROR';
interface NotificationProps extends Pick<SushiNotificationProps, 'key' | 'message' | 'placement'> {
  iconType?: IconType;
  description?: React.ReactNode;
}

type SuccessReturnType = (args: NotificationProps) => void;
type ErrorReturnType = (args: NotificationProps) => void;
type ArrayReturnType = [SuccessReturnType, ErrorReturnType];
type ObjectReturnType = { success: SuccessReturnType; error: ErrorReturnType };

type ReturnType = ArrayReturnType & ObjectReturnType;

export const useNotification = (): ReturnType => {
  const dispatch = useLayoutDispatch();
  const showNotificationError = (args: NotificationProps): void => {
    const icon = args.iconType || <Icon name="cancel" type="filled" className="text-lg text-notification-icon-error" />;
    notification.error({
      message: args.message,
      description: args.description,
      placement: args.placement || 'bottomRight',
      bottom: 43,
      right: 20,
      icon,
      key: args.key || 'save_failed',
      containerClassName: '!bg-red-200 !z-[10000]',
      onClose: () => {
        dispatch({ type: 'NOTIFICATION', payload: false });
      },
      className: '!z-[10000]',
    });
    dispatch({ type: 'NOTIFICATION', payload: true });
  };

  const showNotificationSaveSuccess = (args: NotificationProps): void => {
    const icon = args.iconType || <Icon name="check_circle" type="filled" className="text-lg text-notification-icon-success" />;
    notification.success({
      message: args.message,
      placement: args.placement || 'bottomRight',
      key: args.key || 'save_success',
      bottom: 43,
      right: 20,
      icon,
      containerClassName: '!bg-green-100 !z-[10000]',
      onClose: () => {
        dispatch({ type: 'NOTIFICATION', payload: false });
      },
      className: '!z-[10000]',
    });
    dispatch({ type: 'NOTIFICATION', payload: true });
  };

  const result: ObjectReturnType = {
    success: showNotificationSaveSuccess,
    error: showNotificationError,
  };

  return result as ReturnType;
};
