import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

const DEBUG_REACT_QUERY = process.env.REACT_APP_DEBUG_REACT_QUERY;

export const queryClient = new QueryClient();

export const ReactQueryProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {DEBUG_REACT_QUERY && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};
