import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues } from 'react-hook-form';
import InputTextareaController, { InputTextareaControllerProps } from '@components/form-hook-input/input-textarea/InputTextarea';
import ComponentHorizontalWithLabel, {
  ComponentHorizontalWithLabelProps,
} from '@components/component-with-label/ComponentHorizontalWithLabel';
import labelPlaceholderWithOptional from '@helpers/@utils/placeholder-with-optional';

export interface InputTextareaFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputTextareaControllerProps<TFieldValues, TName>,
    ComponentHorizontalWithLabelProps {}

const InputTextareaForm = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  tooltip,
  placeholder,
  tooltipWidth,
  className,
  optional,
  ...props
}: InputTextareaFormProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);

  return (
    <ComponentHorizontalWithLabel
      labelTop
      className={className}
      label={label}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      optional={optional}
    >
      <InputTextareaController
        placeholder={placeholder || t('please_enter', { label: labelPlaceholderWithOptional(label, !!optional) })}
        {...props}
      />
    </ComponentHorizontalWithLabel>
  );
};

export default InputTextareaForm;
