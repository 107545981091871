import { MenuItemProps } from '@mis/sushi-tailwind-react';
import Home from '@assets/images/home.svg';
import Business from '@assets/images/business.svg';
import Profile from '@assets/images/profile.svg';
import Report from '@assets/images/report.svg';
import FolderManaged from '@assets/images/folder-managed.svg';
import ContactPhone from '@assets/images/contact-phone.svg';
import FileDownload from '@assets/images/file-download.svg';
import { LeftMenuItem } from '@model/left-menu';
import { RequestType } from './flatten-deepest-level-names';
import { DashboardMenuKeys } from '@constants/menu-keys';
import mappingDashboardMenuPath from './mapping-dashboard-menu-path';

type CompanyDetail = {
  id: number;
  authorizeProfileGroupId: number;
  companyDocumentId: number;
};

export function getPageUrlByKey(key: DashboardMenuKeys, urlId?: number | null): MenuItemProps {
  const url = mappingDashboardMenuPath({ key, urlId });
  switch (key) {
    case DashboardMenuKeys.HOME: {
      return {
        label: 'หน้าหลัก',
        key: url,
        itemIcon: <img src={Home} />,
      };
    }
    case DashboardMenuKeys.DETAIL_OF_COMPANY: {
      return {
        label: 'ข้อมูลบริษัท',
        key: url,
        itemIcon: <img src={Business} />,
      };
    }
    case DashboardMenuKeys.COMPANY_INFO: {
      return {
        label: 'ข้อมูลบริษัท',
        key: url,
      };
    }
    case DashboardMenuKeys.AUTHORIZED_SIGNATURE: {
      return {
        label: 'ผู้มีอำนาจลงนาม',
        key: url,
      };
    }
    case DashboardMenuKeys.DOCUMENT_USER_LIST: {
      return {
        label: 'เอกสารรายชื่อผู้ใช้งาน',
        key: url,
      };
    }
    case DashboardMenuKeys.USER_INFO: {
      return {
        label: 'ข้อมูลผู้ใช้งาน',
        key: url,
        itemIcon: <img src={Profile} />,
      };
    }
    case DashboardMenuKeys.APPROVE_USER: {
      return {
        label: 'อนุมัติผู้ใช้',
        key: url,
      };
    }
    case DashboardMenuKeys.ADD_USER: {
      return {
        label: 'เพิ่มผู้ใช้',
        key: url,
      };
    }
    case DashboardMenuKeys.ADD_USER_SEC: {
      return {
        label: 'เพิ่มผู้ใช้ SEC',
        key: url,
      };
    }
    case DashboardMenuKeys.ADD_USER_SET: {
      return {
        label: 'เพิ่มผู้ใช้ SET',
        key: url,
      };
    }
    case DashboardMenuKeys.USER_LIST: {
      return {
        label: 'รายการผู้ใช้',
        key: url,
      };
    }
    case DashboardMenuKeys.USER_LIST_SEC: {
      return {
        label: 'รายการผู้ใช้ SEC',
        key: url,
      };
    }
    case DashboardMenuKeys.USER_LIST_SET: {
      return {
        label: 'รายการผู้ใช้ SET',
        key: url,
      };
    }
    case DashboardMenuKeys.REPORTS_AND_STATISTICS: {
      return {
        label: 'รายงานและสถิติ',
        key: url,
        itemIcon: <img src={Report} />,
      };
    }
    case DashboardMenuKeys.EQUITY_INSTRUMENTS_INFO: {
      return {
        label: 'ข้อมูลฐานตราสารทุน',
        key: url,
      };
    }
    case DashboardMenuKeys.CORPORATE_CONTACT_INFO: {
      return {
        label: 'ข้อมูลผู้ติดต่อ',
        key: url,
      };
    }
    case DashboardMenuKeys.MANAGEMENT: {
      return {
        label: 'การจัดการ',
        key: url,
        itemIcon: <img src={FolderManaged} />,
      };
    }
    case DashboardMenuKeys.AUDITOR: {
      return {
        label: 'Auditor',
        key: url,
      };
    }
    case DashboardMenuKeys.CHECK_SYMBOL_NAME: {
      return {
        label: 'ตรวจสอบชื่อย่อ',
        key: url,
      };
    }
    case DashboardMenuKeys.RESERVE_SPECIAL_SYMBOL: {
      return {
        label: 'จองชื่อย่อพิเศษ',
        key: url,
      };
    }
    case DashboardMenuKeys.COMMON_SYMBOL_LIST: {
      return {
        label: 'รายการชื่อย่อทั่วไป',
        key: url,
      };
    }
    case DashboardMenuKeys.TRUSTEE: {
      return {
        label: 'Trustee',
        key: url,
      };
    }
    case DashboardMenuKeys.COMPANY: {
      return {
        label: 'Company',
        key: url,
      };
    }
    case DashboardMenuKeys.TEMPLATE_OFFERING_DOCUMENT_AND_PO: {
      return {
        label: 'Template เอกสารเสนอขายและเพิ่มทุน',
        key: url,
      };
    }
    case DashboardMenuKeys.TEMPLATE_FILING: {
      return {
        label: 'Template แบบ Filing',
        key: url,
      };
    }
    case DashboardMenuKeys.CONTACT_MANAGEMENT: {
      return {
        label: 'การจัดการข้อมูลผู้ติดต่อ',
        key: url,
        itemIcon: <img src={ContactPhone} />,
      };
    }
    case DashboardMenuKeys.USER_MANUAL_AND_RELATED_DOCUMENTS: {
      return {
        label: 'คู่มือการใช้งานและเอกสารที่เกี่ยวข้อง',
        key: url,
        itemIcon: <img src={FileDownload} />,
      };
    }
    default: {
      return {
        label: '',
        key: '/',
      };
    }
  }
}

export const transformMenu = (menuItems: LeftMenuItem<DashboardMenuKeys>[], company: CompanyDetail): MenuItemProps[] => {
  return menuItems.map(({ menu, children, urlId }) => {
    const menuItemProps: MenuItemProps = getPageUrlByKey(menu, urlId);

    if (children && children.length > 0) {
      menuItemProps.children = transformMenu(children, company);
    }

    return menuItemProps;
  });
};

export const flatten = (menu: RequestType[], parentName = ''): RequestType[] => {
  const flattenedMenu: RequestType[] = [];
  for (const item of menu) {
    const { menu, children, urlId } = item;
    const newItem: RequestType = { menu: menu.replace(parentName, ''), urlId: urlId };

    if (children && children.length > 0) {
      newItem.children = flatten(children, item.menu + '_');
    }

    flattenedMenu.push(newItem);
  }

  return flattenedMenu;
};
