export enum UserRole {
  ROLE_TEMP_ADMIN = 'ROLE_TEMP_ADMIN',
  ROLE_DIPO_ADMIN = 'ROLE_DIPO_ADMIN',
  ROLE_MD_LISTED = 'ROLE_MD_LISTED',
  ROLE_MD = 'ROLE_MD',
  ROLE_APPROVER = 'ROLE_APPROVER',
  ROLE_CREATOR = 'ROLE_CREATOR',
  ROLE_SEC_OFFICER = 'ROLE_SEC_OFFICER',
  ROLE_SEC_DVP = 'ROLE_SEC_DVP',
  ROLE_SEC_VP = 'ROLE_SEC_VP',
  ROLE_SET_OFFICER = 'ROLE_SET_OFFICER',
  ROLE_SET_DVP = 'ROLE_SET_DVP',
  ROLE_SET_VP = 'ROLE_SET_VP',
  ROLE_SET_EVP = 'ROLE_SET_EVP',
  ROLE_SET_TSD = 'ROLE_SET_TSD',
  ROLE_SET_MARKET = 'ROLE_SET_MARKET',
  ROLE_SET_PRESIDENT = 'ROLE_SET_PRESIDENT',
  ROLE_SET_MARKETING = 'ROLE_SET_MARKETING',
  ROLE_SET_RM_OFFICER = 'ROLE_SET_RM_OFFICER',
  ROLE_SET_RM_DVP = 'ROLE_SET_RM_DVP',
  ROLE_SET_RM_VP = 'ROLE_SET_RM_VP',
  ROLE_SET_RM_EVP = 'ROLE_SET_RM_EVP',
  ROLE_SET_IT = 'ROLE_SET_IT',
  ROLE_IPOS_MAKER = 'ROLE_IPOS_MAKER',
  ROLE_IPOS_SENDER = 'ROLE_IPOS_SENDER',
  ROLE_IPOS_VIEWER = 'ROLE_IPOS_VIEWER',
  ROLE_OFAM_MAKER = 'ROLE_OFAM_MAKER',
  ROLE_OFAM_SENDER = 'ROLE_OFAM_SENDER',
  ROLE_OFAM_VIEWER = 'ROLE_OFAM_VIEWER',
  ROLE_MD_APPROVER = 'ROLE_MD_APPROVER',
  ROLE_UNDERWRITER = 'ROLE_UNDERWRITER',
  ROLE_CREATOR_UNDERWRITER = 'ROLE_CREATOR_UNDERWRITER',
  ROLE_APPROVER_UNDERWRITER = 'ROLE_APPROVER_UNDERWRITER',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_SYS_DIPO = 'ROLE_SYS_DIPO',
  ROLE_SYSTEM_ADMIN = 'ROLE_SYSTEM_ADMIN',
}

export enum UserRoleDisplay {
  ROLE_TEMP_ADMIN = 'TEMP ADMIN',
  ROLE_DIPO_ADMIN = 'DIPO ADMIN',
  ROLE_MD_LISTED = 'MD LISTED',
  ROLE_MD = 'MD',
  ROLE_APPROVER = 'APPROVER',
  ROLE_CREATOR = 'CREATOR',
  ROLE_SEC_OFFICER = 'SEC OFFICER',
  ROLE_SEC_DVP = 'SEC DVP',
  ROLE_SEC_VP = 'SEC VP',
  ROLE_SYSTEM_ADMIN = 'SYSTEM ADMIN',
  ROLE_SET_OFFICER = 'SET OFFICER',
  ROLE_SET_DVP = 'SET DVP',
  ROLE_SET_VP = 'SET VP',
  ROLE_SET_EVP = 'SET EVP',
  ROLE_SET_PRESIDENT = 'SET PRESIDENT',
  ROLE_IPOS_MAKER = 'IPOS MAKER',
  ROLE_IPOS_SENDER = 'IPOS SENDER',
  ROLE_IPOS_VIEWER = 'IPOS VIEWER',
  ROLE_OFAM_MAKER = 'OFAM MAKER',
  ROLE_OFAM_SENDER = 'OFAM SENDER',
  ROLE_OFAM_VIEWER = 'OFAM VIEWER',
  ROLE_MD_APPROVER = 'MD APPROVER',
  ROLE_SET_MARKETING = 'SET MARKETING',
  ROLE_UNDERWRITER = 'UNDERWRITER',
  ROLE_CREATOR_UNDERWRITER = 'CREATOR UNDERWRITER',
  ROLE_APPROVER_UNDERWRITER = 'APPROVER UNDERWRITER',
  ROLE_SET_TSD = 'SET TSD',
  ROLE_SET_MARKET = 'SET MARKET',
  ROLE_SET_RM_OFFICER = 'SET RM OFFICER',
  ROLE_SET_RM_DVP = 'SET RM DVP',
  ROLE_SET_RM_VP = 'SET RM VP',
  ROLE_SET_RM_EVP = 'SET RM EVP',
  ROLE_SET_IT = 'SET IT',
  ROLE_SUPER_ADMIN = 'SUPER ADMIN',
  ROLE_SYS_DIPO = 'SYS DIPO',
}
