import { useFormContextWithAdditionalProp } from '@components/form-hook-wrapper/FormHookWrapper';
import InputCheckbox from '@components/input/checkbox/Checkbox';
import { Checkbox } from '@mis/sushi-tailwind-react';
import { FieldController } from '@model/field-controller';
import React from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type CheckboxInputTooltip = string | { selected: string; deselected: string };

export type CheckboxControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Checkbox> & {
    inputTooltip?: CheckboxInputTooltip;
    viewOnly?: boolean;
  };

const CheckboxController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  rules,
  inputTooltip,
  onChange,
  ...props
}: CheckboxControllerProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control, displayErrorMessage } = useFormContextWithAdditionalProp<TFieldValues>();

  const onChangeValue = (o: (...event: AnyValue[]) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    o(e);
    onChange && onChange(e);
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <InputCheckbox
          {...field}
          {...props}
          value={props.value || field.value || undefined}
          inputTooltip={inputTooltip}
          id={name}
          onChange={onChangeValue(field.onChange)}
          message={displayErrorMessage && error ? t(error?.message as string) : undefined}
          status={displayErrorMessage && error ? 'error' : undefined}
        />
      )}
    />
  );
};

export default CheckboxController;
