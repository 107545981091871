import { LayoutProvider } from '@context/Layout.context';
import { LiveChatProvider } from '@context/LiveChat.context';
import { ProfileProvider } from '@context/Profile.context';
import { ReactQueryProvider } from '@context/ReactQuery.context';
import { SushiTailwindThemeProvider, SushiTailwindThemeProviderProps } from '@mis/sushi-tailwind-react';

interface NewSushiTailwindThemeProviderProps extends Omit<SushiTailwindThemeProviderProps, 'children'> {
  children: React.ReactNode;
}

function NewSushiTailwindThemeProvider({ children }: { children: React.ReactNode }) {
  const NewSushiTailwindThemeProvider: React.FC<NewSushiTailwindThemeProviderProps> = SushiTailwindThemeProvider;
  return <NewSushiTailwindThemeProvider>{children}</NewSushiTailwindThemeProvider>;
}

const ProviderConfiguration: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <LayoutProvider collapsed={false}>
      <NewSushiTailwindThemeProvider>
        <ReactQueryProvider>
          <ProfileProvider profile={null} passwordExpired={null}>
            <LiveChatProvider initial={false}>{children}</LiveChatProvider>
          </ProfileProvider>
        </ReactQueryProvider>
      </NewSushiTailwindThemeProvider>
    </LayoutProvider>
  );
};

export default ProviderConfiguration;
