import React from 'react';

const SVGColor = (): React.ReactElement => {
  return (
    <>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="cl1" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1" gradientTransform="rotate(50)">
            <stop offset="0%" stopColor="#FFCF36" />
            <stop offset="100%" stopColor="#FCB034" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default SVGColor;
