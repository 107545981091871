import React from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input-string/InputString';
import { useFormContextWithAdditionalProp } from '@components/form-hook-wrapper/FormHookWrapper';

export type InputStringControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Input> & {
    interactiveError?: boolean;
  };

const InputStringController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  rules,
  placeholder,
  interactiveError,
  ...props
}: InputStringControllerProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control, displayErrorMessage } = useFormContextWithAdditionalProp<TFieldValues>();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        const displayError = (interactiveError || displayErrorMessage) && error;
        return (
          <Input
            {...field}
            {...props}
            value={props.value || field.value || ''}
            placeholder={props.viewOnly ? undefined : placeholder || t('other.please_enter')}
            message={displayError ? t(error?.message as string) : undefined}
            status={displayError ? 'error' : undefined}
            data-testid={name}
          />
        );
      }}
    />
  );
};

export default InputStringController;
