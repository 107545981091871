import { DashboardMenuKeys } from '@constants/menu-keys';
import { ApplicationClassicRouteService } from '@containers/application/services/application-classic-route.service';

export interface MappingMenuPathArgs {
  key: DashboardMenuKeys;
  urlId?: number | null;
}

const mappingDashboardMenuPath = ({ key, urlId }: MappingMenuPathArgs): string => {
  switch (key) {
    // Symbol menu
    case DashboardMenuKeys.HOME:
      return '/dashboard';
    case DashboardMenuKeys.DETAIL_OF_COMPANY:
      return DashboardMenuKeys.DETAIL_OF_COMPANY;
    case DashboardMenuKeys.COMPANY_INFO:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: `/company/show/${urlId}` });
    case DashboardMenuKeys.AUTHORIZED_SIGNATURE:
      return ApplicationClassicRouteService.generateClassicDipoUrl({
        destinationPath: urlId ? `/authorizeProfileGroup/edit/${urlId}` : `/authorizeProfileGroup/create`,
      });
    case DashboardMenuKeys.DOCUMENT_USER_LIST:
      return ApplicationClassicRouteService.generateClassicDipoUrl({
        destinationPath: urlId ? `/companyDocument/edit/${urlId}` : `/companyDocument/create`,
      });
    case DashboardMenuKeys.USER_INFO:
      return DashboardMenuKeys.USER_INFO;
    case DashboardMenuKeys.APPROVE_USER:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/waitApprove?userStatus=REQUEST' });
    case DashboardMenuKeys.ADD_USER:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/create' });
    case DashboardMenuKeys.ADD_USER_SEC:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/create?super=sec' });
    case DashboardMenuKeys.ADD_USER_SET:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/create?super=set' });
    case DashboardMenuKeys.USER_LIST:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/index' });
    case DashboardMenuKeys.USER_LIST_SEC:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/index?super=sec' });
    case DashboardMenuKeys.USER_LIST_SET:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/userProfile/index?super=set' });
    case DashboardMenuKeys.REPORTS_AND_STATISTICS:
      return DashboardMenuKeys.REPORTS_AND_STATISTICS;
    case DashboardMenuKeys.EQUITY_INSTRUMENTS_INFO:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/equityReport/index' });
    case DashboardMenuKeys.CORPORATE_CONTACT_INFO:
      return '/admin/report/contact';
    case DashboardMenuKeys.MANAGEMENT:
      return DashboardMenuKeys.MANAGEMENT;
    case DashboardMenuKeys.AUDITOR:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/auditorFirm/index' });
    case DashboardMenuKeys.CHECK_SYMBOL_NAME:
      return '/check-symbol-name';
    case DashboardMenuKeys.RESERVE_SPECIAL_SYMBOL:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/manageSymbol/index' });
    case DashboardMenuKeys.COMMON_SYMBOL_LIST:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/reservedSymbolAdmin/index' });
    case DashboardMenuKeys.TRUSTEE:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/trustee/index' });
    case DashboardMenuKeys.COMPANY:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/company/index' });
    case DashboardMenuKeys.TEMPLATE_OFFERING_DOCUMENT_AND_PO:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/templateReportUpload/index' });
    case DashboardMenuKeys.TEMPLATE_FILING:
      return ApplicationClassicRouteService.generateClassicDipoUrl({ destinationPath: '/templateReportUpload/filing' });
    case DashboardMenuKeys.CONTACT_MANAGEMENT:
      return '/contact/manage/ceo';
    case DashboardMenuKeys.USER_MANUAL_AND_RELATED_DOCUMENTS:
      return '/download/digital-ipo';
    default:
      return '';
  }
};

export default mappingDashboardMenuPath;
