import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { InputUpload as InputFile } from '@mis/sushi-tailwind-react';
import { useNotification } from '@helpers/@hooks/useNotification';

export type InputUploadControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  Omit<React.ComponentProps<typeof InputFile>, 'onChange'> & {
    onChange?: (file: File | null) => void;
    leftAcceptLabel?: React.ReactNode;
  };

const InputUploadController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  rules,
  placeholder,
  acceptLabel,
  maxFileSizeNumber,
  onChange,
  leftAcceptLabel,
  ...props
}: InputUploadControllerProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label', 'error']);
  const { control, setError } = useFormContext<TFieldValues>();
  const notification = useNotification();
  const onChangeValue = (change: (...event: (File | null)[]) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file?.size && maxFileSizeNumber && file?.size > maxFileSizeNumber) {
      notification.error({ message: t('error:feedback.file_size_too_large') });
      onChange ? onChange(null) : change(null);
      return;
    }
    onChange ? onChange(file) : change(file);
  };
  const onError = (change: (...event: (File | null)[]) => void) => (e: { message: string }) => {
    change(null);
    setError(name, { type: 'manual', message: e.message });
  };
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <div ref={ref}>
            <InputFile
              {...field}
              {...props}
              fileName={(field.value as File)?.name || ''}
              acceptLabel={
                <div className="mt-0.5 flex flex-row justify-between">
                  <div>{leftAcceptLabel}</div>
                  {error ? (
                    <span className="text-red-500">{t(error?.message as string)}</span>
                  ) : (
                    <div className="text-xs">{acceptLabel}</div>
                  )}
                </div>
              }
              onChange={onChangeValue(field.onChange)}
              onError={onError(field.onChange)}
              id={name}
              error={!!error}
              placeholder={placeholder || t('other.please_enter')}
              data-testid={name}
              placeholderClassName={field.value ? 'text-black' : undefined}
            />
          </div>
        );
      }}
    />
  );
};

export default InputUploadController;
