import { authAxios, axios } from '@core/axios';
import { TokenResponse } from '@model/response/token.response';
import { AxiosResponse } from 'axios';
import { delay, from, map, Observable, tap } from 'rxjs';

export class TokenService {
  private static key = 'token';
  private static keyRefresh = 'refresh-token';

  public static getToken(): string | null {
    return localStorage.getItem(this.key) || null;
  }

  public static setToken(token: string): void {
    localStorage.setItem(this.key, token || '');
  }

  public static clearToken(): void {
    localStorage.removeItem(this.key);
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(this.keyRefresh) || null;
  }

  public static setRefreshToken(token: string): void {
    localStorage.setItem(this.keyRefresh, token);
  }

  public static clearRefreshToken(): void {
    localStorage.removeItem(this.keyRefresh);
  }

  public static refreshToken(): Observable<AxiosResponse<TokenResponse>> {
    const refreshToken = TokenService.getRefreshToken();
    return from(
      authAxios.post<TokenResponse>(`/refreshToken`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
    ).pipe(
      tap(({ data }) => {
        TokenService.setToken(data.token);
        TokenService.setRefreshToken(data.refreshToken);
      }),
      delay(1000)
    );
  }

  public static getLiveChatToken(): Observable<TokenResponse> {
    const refreshToken = TokenService.getRefreshToken();
    return from(
      axios.post<TokenResponse>(`/live-chat/refresh`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
    ).pipe(map(({ data }) => data));
  }
}
