import React, { useMemo } from 'react';
import { ITooltip, Tooltip as RTooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';

export interface TooltipProps extends Omit<ITooltip, 'content' | 'children' | 'id'> {
  children?: React.ReactNode;
  content?: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, ...tooltipProps }) => {
  const id = useMemo(() => uuid(), []);
  return (
    <>
      <a data-tooltip-id={id}>{children}</a>
      <RTooltip id={id} clickable {...tooltipProps}>
        {content}
      </RTooltip>
    </>
  );
};

export default Tooltip;
