import React from 'react';
import { Checkbox, Icon, Tooltip } from '@mis/sushi-tailwind-react';

export type CheckboxInputTooltip = string | { selected: string; deselected: string };

export type CheckboxProps = Omit<React.ComponentProps<typeof Checkbox>, 'status'> & {
  allowOnly?: 'en' | 'th';
  status?: React.ComponentProps<typeof Checkbox>['status'];
  viewOnly?: boolean;
  inputTooltip?: CheckboxInputTooltip;
};

const CheckboxCheckViewOnly = () => <Icon className="w-5 text-primary-900" name="check" />;
const CheckboxUncheckViewOnly = () => (
  <div className="mr-4px flex h-16px min-h-[16px] w-16px min-w-[16px] items-center justify-center rounded-4px border border-primary-900 bg-dark-10" />
);

const InputCheckbox: React.FC<CheckboxProps> = ({ viewOnly, inputTooltip, ...props }) => {
  const getTooltip = (tooltip: CheckboxInputTooltip | undefined, selected: boolean) => {
    if (typeof tooltip === 'string') {
      return tooltip;
    }
    if (typeof tooltip === 'object') {
      return selected ? tooltip.selected : tooltip.deselected;
    }
  };

  if (viewOnly) {
    if (props.value) {
      return <CheckboxCheckViewOnly />;
    }
    return <CheckboxUncheckViewOnly />;
  }

  const renderCheckbox = (
    <Checkbox data-testid={props.id} {...props} checked={props.value || false} value={props.value ? 'true' : 'false'} />
  );
  const tooltip = getTooltip(inputTooltip, props.value);

  return tooltip ? (
    <Tooltip title={tooltip} placement="topLeft">
      {renderCheckbox}
    </Tooltip>
  ) : (
    renderCheckbox
  );
};

export default InputCheckbox;
