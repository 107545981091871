import React, { useMemo } from 'react';
import { TextDisplay } from '@components/text-display/TextDisplay';
import { Dropdown, DropdownOption } from '@mis/sushi-tailwind-react';
import isFullEmptyObject from '@helpers/@utils/is-full-empty-object';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const CustomDropdown = styled(Dropdown)`
  & .ss-select-selection-item {
    color: black !important;
  }
`;

export type InputDropdownProps = Omit<React.ComponentProps<typeof Dropdown>, 'status'> & {
  viewOnly?: boolean;
  overModal?: boolean;
  status?: React.ComponentProps<typeof Dropdown>['status'];
};

const InputDropdown: React.FC<InputDropdownProps> = ({ viewOnly, options = [], overModal, ...props }) => {
  const { t } = useTranslation();
  const onChangeValue = (val: unknown, options: DropdownOption[]) => {
    const value = val === undefined ? null : val;
    props.onChange && props.onChange?.(value, options);
  };
  const dropdownStyle = useMemo<React.CSSProperties | undefined>(() => {
    let style: React.CSSProperties = {};
    if (props.dropdownStyle) {
      style = props.dropdownStyle;
    }
    if (overModal) {
      style = { ...props.dropdownStyle, zIndex: 10000 };
    }
    return !isFullEmptyObject(style) ? style : undefined;
  }, [overModal, props.dropdownStyle]);

  if (viewOnly) {
    return <TextDisplay name={props.name} value={options.find((i) => i.value === props.value)?.label || props.value} />;
  }
  return (
    <CustomDropdown
      showSearch
      optionFilterProp="label"
      {...props}
      showArrow={props.showArrow || !props.disabled}
      onChange={onChangeValue}
      options={options}
      data-testid={props.name}
      dropdownStyle={dropdownStyle}
      notFoundContent={props.notFoundContent || t('translation:no_data')}
    />
  );
};

export default InputDropdown;
