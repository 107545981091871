import { ClassicDIPOService } from '@core/services/classic-dipo.service';
import { SecurityType } from '@model/enum/security-type.enum';

export class ApplicationClassicRouteService extends ClassicDIPOService {
  public static timeline(appId?: number | null, pathId?: number): string {
    return pathId
      ? super.generateClassicDipoUrl({ destinationPath: `/timeline/show/${pathId}` })
      : super.generateClassicDipoUrl({
          destinationPath: '/timeline/create',
          destinationSearchParams: { appId: appId?.toString() },
        });
  }

  public static companyProfile(appId?: number | null, securityType?: SecurityType, pathId?: number): string {
    let domain = '';
    if (securityType === SecurityType.CS) {
      domain = 'commonCompanyProfile';
    } else if (securityType === SecurityType.REIT) {
      domain = 'realEstateInvestmentTrustCompanyProfile';
    } else if (securityType === SecurityType.IFF) {
      domain = 'infraFundCompanyProfile';
    } else if (securityType === SecurityType.ETF) {
      domain = 'exchangeTradedFundCompanyProfile';
    } else if (securityType === SecurityType.CS_FOREIGN) {
      domain = 'generalCompany';
    }
    return pathId
      ? super.generateClassicDipoUrl({ destinationPath: `/${domain}/show/${pathId}` })
      : super.generateClassicDipoUrl({
          destinationPath: `/${domain}/create`,
          destinationSearchParams: { appId: appId?.toString() },
        });
  }

  public static preApproveDoc(appId?: number | null): string {
    return super.generateClassicDipoUrl({
      destinationPath: `/preApproveDoc/edit`,
      destinationSearchParams: { appId: appId?.toString() },
    });
  }

  public static uploadDoc(appId?: number | null): string {
    return super.generateClassicDipoUrl({
      destinationPath: `/uploadDoc/edit`,
      destinationSearchParams: { appId: appId?.toString() },
    });
  }

  public static filing(appId?: number | null): string {
    return super.generateClassicDipoUrl({
      destinationPath: `/filing/edit`,
      destinationSearchParams: { appId: appId?.toString() },
    });
  }

  public static fscomp(appId?: number | null): string {
    return super.generateClassicDipoUrl({
      destinationPath: `/fscomp/edit`,
      destinationSearchParams: { appId: appId?.toString() },
    });
  }

  public static submitFormAndFiling(appId?: number | null): string {
    return appId ? super.generateURLApplication(appId) : '';
  }

  public static underwriter(id?: number | null, appId?: number | null): string {
    return appId
      ? super.generateClassicDipoUrl({
          destinationPath: id ? `/assignedUnderwriterTeam/edit/${id}` : `/assignedUnderwriterTeam/create?appId=${appId}`,
        })
      : '';
  }

  public static tradingInfo(appId?: number | null, securityType?: SecurityType, pathId?: number): string {
    let domain = '';
    if (securityType === SecurityType.CS) {
      domain = 'commonTradingInfo';
    } else if (securityType === SecurityType.REIT) {
      domain = 'realEstateInvestmentTrustTradingInfo';
    } else if (securityType === SecurityType.IFF) {
      domain = 'infraFundTradingInfo';
    } else if (securityType === SecurityType.CS_FOREIGN) {
      domain = 'commonForeignTradingInfo';
    }
    return pathId
      ? super.generateClassicDipoUrl({
          destinationPath: `/${domain}/show/${pathId}`,
          destinationSearchParams: { appId: appId?.toString() },
        })
      : super.generateClassicDipoUrl({
          destinationPath: `/${domain}/create`,
          destinationSearchParams: { appId: appId?.toString() },
        });
  }

  public static secConsiderProcess(pathId?: number): string {
    return super.generateClassicDipoUrl({
      destinationPath: `/secConsideredProcess/edit/${pathId}`,
    });
  }

  public static setConsiderProcess(pathId?: number): string {
    return super.generateClassicDipoUrl({
      destinationPath: `/setConsideredProcess/edit/${pathId}`,
    });
  }
}
