import { ApplicationService } from '@core/services/application.service';
import { AssignedTeamService } from '@core/services/assigned-team.service';
import { ProfileService } from '@core/services/profile.service';
import { ReservedSymbolService } from '@core/services/reserved-symbol.service';
import { TokenService } from '@core/services/token.service';
import { ApplicationMenuItem } from '@model/application-menu-item';
import { ApplicationStatus } from '@model/application-status';
import { SecurityType } from '@model/enum/security-type.enum';
import { Profile } from '@model/profile';
import { ReservedSymbolType } from '@model/reserved-symbol';
import { AssignedTeamResponse } from '@model/response/assigned-team.response';
import { DraftProfileResponse } from '@model/response/draft-profile.response';
import { firstValueFrom, forkJoin, of, switchMap } from 'rxjs';

export interface ReservedSymbolDefaultData {
  profile: Profile;
  passwordExpired: boolean;
  menus: ApplicationMenuItem[] | null;
  store: {
    reservedSymbol: DraftProfileResponse<ReservedSymbolType>;
    assignedTeam: AssignedTeamResponse;
    appStatus: ApplicationStatus | null;
  } | null;
}

export default async function getReservedSymbolDefaultData(
  symbolId: string,
  securityType: string
): Promise<Partial<ReservedSymbolDefaultData>> {
  const jwt = TokenService.getToken();
  const data$ = forkJoin({
    profile: ProfileService.getProfile(),
    passwordExpired: ProfileService.getChangePassword(jwt as string),
    menus: !isNaN(Number(symbolId)) ? ApplicationService.getMenus(Number(symbolId)) : of(null),
    store:
      !isNaN(Number(symbolId)) && securityType
        ? ReservedSymbolService.getSymbol(Number(symbolId), securityType as SecurityType).pipe(
            switchMap((symbol) =>
              forkJoin({
                reservedSymbol: of(symbol),
                assignedTeam: AssignedTeamService.getAssignedTeam(symbol.current.assignedTeamId),
                appStatus: ApplicationService.getStatusApplicationByReservedSymbolId(Number(symbolId)),
                applicationAccess: ReservedSymbolService.checkApplicationAccess(Number(symbolId)),
              })
            )
          )
        : of(null),
  });

  return await firstValueFrom(data$);
}
