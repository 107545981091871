import React from 'react';
import { Button, ButtonProps } from '@mis/sushi-tailwind-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const TButton = styled(Button)`
  ${({ disabled, loading }) =>
    !disabled && !loading
      ? css`
          color: #00a7cc;
          background: none;
          box-shadow: none;
          &:hover {
            color: #57c3db;
            background: none;
            box-shadow: none;
          }
          &:active {
            color: #abe1ed;
            background: none;
          }
        `
      : css`
          background-color: transparent !important;
        `}
`;

export const TransparentButton: React.FC<ButtonProps> = (props) => {
  return <TButton type="button" {...props} />;
};
