import React from 'react';
import { ButtonProps, Icon } from '@mis/sushi-tailwind-react';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

export const CompanyEditButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button.Secondary icon={<Icon name="edit" />} data-testid="companyEditButton" {...props}>
      {t('button:edit')}
    </Button.Secondary>
  );
};
