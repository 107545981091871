import { Observable, from } from 'rxjs';
import { axios } from '@core/axios';
import { map } from 'rxjs/operators';
import { DIPOBackBaseResponse, MessageResponse } from '@model/response/@base-response/dipo-back-base-response';
import { FinancialAdvisorMasterResponse } from '@model/response/financial-advisor-master.reponse';
import {
  AssignedTeamDropdownResponse,
  AssignedTeamResponse,
  RegulatorSECRequest,
  RegulatorSETRequest,
} from '@model/response/assigned-team.response';
import { FinancialAdvisorRequest } from '@containers/reserved-symbol/models/assigned-team/assigned-team-request';

export class AssignedTeamService {
  static basePath = '/assignedTeam';
  public static getFinancialAdvisors(companyId?: number): Observable<FinancialAdvisorMasterResponse> {
    const pathQuery = companyId ? `/company/${companyId}` : '';
    const url = `${this.basePath}/fa${pathQuery}`;
    return from(axios.get<DIPOBackBaseResponse<FinancialAdvisorMasterResponse>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static getAssignedTeam(assignedTeamId: number): Observable<AssignedTeamResponse> {
    const url = `${this.basePath}/${assignedTeamId}`;
    return from(axios.get<DIPOBackBaseResponse<AssignedTeamResponse>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateFinancialAdviser(assignedTeamId: number, req: FinancialAdvisorRequest): Observable<MessageResponse> {
    const url = `${this.basePath}/${assignedTeamId}`;
    return from(axios.put<MessageResponse>(url, req)).pipe(map(({ data }) => data));
  }

  public static getAssignedTeamDropdown(): Observable<AssignedTeamDropdownResponse> {
    const url = `${this.basePath}/regulator/`;
    return from(axios.get<DIPOBackBaseResponse<AssignedTeamDropdownResponse>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateAssignedTeamSEC(assignedTeamId: number, req: RegulatorSECRequest): Observable<MessageResponse> {
    const url = `${this.basePath}/sec/${assignedTeamId}`;
    return from(axios.put<MessageResponse>(url, req)).pipe(map(({ data }) => data));
  }

  public static updateAssignedTeamSET(assignedTeamId: number, req: RegulatorSETRequest): Observable<MessageResponse> {
    const url = `${this.basePath}/set/${assignedTeamId}`;
    return from(axios.put<MessageResponse>(url, req)).pipe(map(({ data }) => data));
  }
}
