import React from 'react';
import { ButtonProps, Icon } from '@mis/sushi-tailwind-react';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

interface CustomButtonProps extends ButtonProps {
  children?: React.ReactNode;
  typeBtn?: 'save';
}

export const UpdateButton: React.FC<CustomButtonProps> = ({ typeBtn, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button.Primary
      icon={typeBtn === 'save' ? <Icon name="save" /> : <Icon name="autorenew" />}
      data-testid="companyUpdateButton"
      {...props}
    >
      {t('button:save')}
    </Button.Primary>
  );
};
