import React from 'react';
import { Textarea } from '@mis/sushi-tailwind-react';
import { TextDisplay } from '@components/text-display/TextDisplay';
import { replaceAllowCharacter } from '@helpers/@utils/replace-allow-characters';
import { v4 as uuid } from 'uuid';

export type InputTextareaProps = Omit<React.ComponentProps<typeof Textarea>, 'status'> & {
  allowOnly?: 'en' | 'th';
  status?: React.ComponentProps<typeof Textarea>['status'];
  viewOnly?: boolean;
};

const InputTextarea: React.FC<InputTextareaProps> = ({ allowOnly, onChange, viewOnly, ...props }) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!allowOnly || !e.target.value) return onChange?.(e);
    const event = replaceAllowCharacter(e, allowOnly);
    onChange?.(event);
  };

  if (viewOnly) {
    return <TextDisplay name={props.id || uuid()} value={props.value === null ? '' : props.value?.toString()} />;
  }

  return (
    <Textarea
      data-testid={props.id}
      autoComplete="off"
      readOnly={viewOnly}
      showCount={props.maxLength !== undefined}
      {...props}
      value={props.value === null ? '' : props.value}
      onChange={onChangeValue}
    />
  );
};

export default InputTextarea;
