import React from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@mis/sushi-tailwind-react';
import { useFormContextWithAdditionalProp } from '@components/form-hook-wrapper/FormHookWrapper';
import InputTextarea from '@components/input/input-textarea/InputTextarea';

export type InputTextareaControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Textarea> & {
    viewOnly?: boolean;
    allowOnly?: 'en' | 'th';
  };

const InputTextareaController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  rules,
  placeholder,
  ...props
}: InputTextareaControllerProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control, displayErrorMessage } = useFormContextWithAdditionalProp<TFieldValues>();
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <InputTextarea
            {...field}
            {...props}
            value={props.value || field.value || ''}
            placeholder={props.viewOnly ? undefined : placeholder || t('other.please_enter')}
            message={displayErrorMessage && error ? t(error?.message as string) : undefined}
            status={displayErrorMessage && error ? 'error' : undefined}
            data-testid={name}
          />
        );
      }}
    />
  );
};

export default InputTextareaController;
