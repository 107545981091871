import { CompanyEditButton } from './edit-button/EditButton';
import { CompanyPreviewButton } from './preview-button/PreviewButton';
import { CompanySaveButton } from './save-button/SaveButton';
import { CompanyReserveButton } from './send-approve-button/CompanyReserveButton';
import { UpdateButton } from './update-button/EditButton';

const Edit = CompanyEditButton;
const Preview = CompanyPreviewButton;
const Update = UpdateButton;
const Save = CompanySaveButton;
const Reserve = CompanyReserveButton;

export { Edit, Preview, Save, Update, Reserve };
