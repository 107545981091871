import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps, Icon } from '@mis/sushi-tailwind-react';
import classNames from 'classnames';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const DeleteBtn = styled(Button)`
  ${({ disabled, loading }) =>
    !disabled &&
    !loading &&
    css`
      border: 1px solid #c60651;
      color: #c60651;
      background-image: linear-gradient(180deg, #ffffff 0%, #efefef 88.02%);
      &:hover {
        border: 1px solid #b60000;
      }
      &:active {
        border: 1px solid #ecacc4;
      }
    `}
`;

export const DeleteButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const buttonClassName = classNames({ 'text-[#c60651]': !props.disabled });
  return (
    <DeleteBtn icon={<Icon name="delete" className={buttonClassName} />} type="button" {...props}>
      {children || t('button:delete')}
    </DeleteBtn>
  );
};
