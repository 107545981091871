import { useLocation } from '@tanstack/react-location';
import React, { useEffect } from 'react';

const ScrollToTop: React.FC = () => {
  const {
    history: { location },
  } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;
