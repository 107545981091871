import React from 'react';
import { Button, ButtonProps } from '@mis/sushi-tailwind-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Green = styled(Button)`
  ${({ disabled, loading }) =>
    !disabled &&
    !loading &&
    css`
      background: #d8e77e;
      :hover {
        box-shadow: 0 0 0 1px #006600;
      }
      :active {
        box-shadow: 0 0 0 1px #f2f7d4;
      }
    `}
`;

export const GreenButton: React.FC<ButtonProps> = (props) => {
  return <Green type="button" data-testid="btnGreen" {...props} />;
};
