import React, { useCallback, useImperativeHandle } from 'react';
import {
  FieldErrors,
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
  SubmitHandler as Submit,
  Path,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { preventFormSubmit } from '@helpers/@utils/prevent-form-submit';
import TriggerFormInitially from './TriggerFormInitially';

export type SubmitHandler<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
> = (
  data: TFieldValues,
  args: ExtendedUseFormReturn<TFieldValues, TContext, TTransformedValues>,
  event?: React.BaseSyntheticEvent
) => unknown | Promise<unknown>;

export type SubmitErrorHandler<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
> = (
  data: FieldErrors<TFieldValues>,
  event?: React.BaseSyntheticEvent,
  args?: UseFormReturn<TFieldValues, TContext, TTransformedValues>
) => unknown | Promise<unknown>;

export interface FormHookWrapperProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
> extends UseFormProps<TFieldValues, TContext> {
  validateInitially?: boolean;
  validationSchema?: AnyValue | (() => AnyValue);
  preventEnterSubmit?: boolean;
  validateAfterSubmitted?: boolean;
  onSubmit: SubmitHandler<TFieldValues, TContext, TTransformedValues>;
  onError?: SubmitErrorHandler<TFieldValues, TContext, TTransformedValues> | undefined;
  handleSubmit?: (
    e: React.BaseSyntheticEvent | undefined,
    args: ExtendedUseFormReturn<TFieldValues, TContext, TTransformedValues>
  ) => Promise<void>;
  children?: (args: ExtendedUseFormReturn<TFieldValues, TContext, TTransformedValues>) => React.ReactNode;
}

export type FormHookWrapperRef<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
> = ExtendedUseFormReturn<TFieldValues, TContext, TTransformedValues> & {
  submit: () => Promise<void>;
};

function FormHookWrapperInner<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
>(
  {
    children,
    onSubmit,
    onError,
    handleSubmit,
    validateInitially,
    validationSchema,
    preventEnterSubmit = true,
    validateAfterSubmitted = false,
    mode = 'onChange',
    ...props
  }: FormHookWrapperProps<TFieldValues, TContext, TTransformedValues>,
  ref: React.ForwardedRef<FormHookWrapperRef<TFieldValues, TContext, TTransformedValues>>
): JSX.Element {
  const args = useForm<TFieldValues, TContext, TTransformedValues>({
    mode,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    ...props,
  }) as ExtendedUseFormReturn<TFieldValues, TContext, TTransformedValues>;

  args.displayErrorMessage = validateAfterSubmitted ? args.formState.isSubmitted : true;

  args.rerender = (values, options) => {
    args.reset(values, {
      keepValues: !values,
      keepIsSubmitted: true,
      keepDefaultValues: true,
      keepErrors: true,
      keepIsSubmitSuccessful: true,
      keepSubmitCount: true,
      keepDirty: true,
      keepTouched: true,
      ...options,
    });
    if (values) {
      args.trigger();
    }
  };

  args.setValues = (values, options) => {
    Object.keys(values).forEach((key) => {
      args.setValue(key as keyof TFieldValues as Path<TFieldValues>, values[key]);
    });
    if (options?.shouldValidate) {
      args.trigger();
    }
  };

  const onFormSubmit: Submit<TFieldValues> = useCallback(
    (data: TFieldValues, event?: React.BaseSyntheticEvent) => {
      return onSubmit(data, args, event);
    },
    [args, onSubmit]
  );

  const onFormError = useCallback(
    (data: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent) => {
      return onError && onError(data, event, args);
    },
    [args, onError]
  );

  useImperativeHandle(ref, () => ({
    ...args,
    submit: handleManualSubmit,
  }));

  const handleManualSubmit = (e?: React.BaseSyntheticEvent) => {
    e?.preventDefault();
    return handleSubmit ? handleSubmit(e, args) : args.handleSubmit(onFormSubmit as AnyValue, onFormError)();
  };

  return (
    <FormProvider {...args}>
      <>
        <form onKeyDown={preventEnterSubmit ? preventFormSubmit : undefined} onSubmit={handleManualSubmit}>
          {children && children(args)}
        </form>
        {validateInitially && <TriggerFormInitially<TFieldValues, TContext, TTransformedValues> {...args} />}
      </>
    </FormProvider>
  );
}

const FormHookWrapper = React.forwardRef(FormHookWrapperInner) as <
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
>(
  props: FormHookWrapperProps<TFieldValues, TContext, TTransformedValues> & {
    ref?: React.ForwardedRef<FormHookWrapperRef<TFieldValues, TContext, TTransformedValues>>;
  }
) => ReturnType<typeof FormHookWrapperInner<TFieldValues, TContext, TTransformedValues>>;

export interface ExtendedUseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
> extends UseFormReturn<TFieldValues, TContext, TTransformedValues> {
  displayErrorMessage: boolean;
  rerender: UseFormReturn<TFieldValues, TContext, TTransformedValues>['reset'];
  setValues: (values: TFieldValues, options?: { shouldValidate: boolean }) => void;
}

const useFormContextWithAdditionalProp = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
>(): ExtendedUseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  return useFormContext<TFieldValues, TContext, TTransformedValues>() as unknown as ExtendedUseFormReturn<
    TFieldValues,
    TContext,
    TTransformedValues
  >;
};

export { useFormContextWithAdditionalProp };

export default FormHookWrapper;
