import { env } from '@core/env';
import { TokenService } from './token.service';

export class ClassicDIPOService {
  public static generateClassicDipoUrl({
    destinationPath,
    destinationSearchParams,
    token,
  }: {
    destinationPath?: string;
    destinationSearchParams?: { [key: string]: string | undefined };
    token?: string;
  }): string {
    const url = new URL(`${env.classicDipoURL}/login/ssodipo`);
    if (destinationPath) {
      let destinationRedirect = destinationPath;
      destinationSearchParams &&
        Object.keys(destinationSearchParams).forEach((key, index) => {
          const value = destinationSearchParams[key as keyof typeof destinationSearchParams];
          if (value) destinationRedirect = destinationRedirect + `${index === 0 ? '?' : '&'}${key}=${value}`;
        });
      url.searchParams.set('destinationRedirect', destinationRedirect);
    }
    url.searchParams.set('token', token || TokenService.getToken() || '');
    return url.toString();
  }
  public static generateURLHomepage(token?: string): string {
    return this.generateClassicDipoUrl({ token });
  }
  public static generateURLForgetPassword(): string {
    return `${env.classicDipoURL}/userProfile/forget`;
  }
  public static generateURLRegister(): string {
    return `${env.classicDipoURL}/userProfile/register`;
  }
  public static generateURLApplication(appId: number, token?: string): string {
    return this.generateClassicDipoUrl({
      destinationPath: '/application/index',
      destinationSearchParams: { appId: appId.toString() },
      token,
    });
  }
}
