import { Icon, Tooltip } from '@mis/sushi-tailwind-react';
import classNames from 'classnames';
import Label from './ComponentLabel';
import Children from './ComponentChildren';
import React from 'react';
export interface ComponentHorizontalWithLabelProps {
  children?: React.ReactNode;
  label?: React.ReactNode;
  tooltip?: string;
  tooltipWidth?: number;
  ['data-testid']?: string;
  className?: string;
  optional?: boolean | string;
  containerCss?: React.CSSProperties;
  labelCss?: React.CSSProperties;
  labelTop?: boolean;
}

interface ComponentHorizontalWithLabelInterface extends React.ForwardRefExoticComponent<ComponentHorizontalWithLabelProps> {
  Label: typeof Label;
  Children: typeof Children;
}

const excludeChildrenOnDisplayName = (children: React.ReactNode, displayName: string[]): React.ReactNode[] | null | undefined => {
  return React.Children.map(children, (child) => {
    return (child as AnyValue).type && displayName.includes((child as AnyValue).type.displayName) ? null : child;
  });
};

const ComponentHorizontalWithLabel: React.FC<ComponentHorizontalWithLabelProps> = React.forwardRef<
  HTMLDivElement,
  ComponentHorizontalWithLabelProps
>(
  (
    { children, className, label, tooltip, tooltipWidth, optional, containerCss, labelCss, labelTop, ...props },
    forwardRef
  ): JSX.Element => {
    const cn = classNames(['flex flex-1 flex-col md:flex-row gap-4', labelTop ? '' : 'md:items-center', className]);
    const childrenExclude = excludeChildrenOnDisplayName(children, ['ComponentLabel', 'ComponentChildren']);
    const testid = props['data-testid'] || 'component-with-label';
    return (
      <div {...props} className={cn} style={containerCss} ref={forwardRef} data-testid={testid}>
        <div className="flex w-[100%] pl-2 md:w-[220px] md:justify-end md:text-right lg:w-[30%]">
          <label className={`flex ${labelTop ? 'pt-1' : ''} md:flex-col`} style={labelCss}>
            {label}
            {optional && <span className="mb-[1px] self-end text-xxs">{`(${typeof optional === 'string' ? optional : 'ถ้ามี'})`}</span>}
          </label>
          {tooltip && (
            <div className="flex cursor-default items-center self-center pl-2">
              <Tooltip width={tooltipWidth} placement="topLeft" title={tooltip}>
                <Icon name="help_outline" />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="flex-1 overflow-hidden p-0.5" data-testid={`${testid}-children`}>
          {childrenExclude}
        </div>
      </div>
    );
  }
) as ComponentHorizontalWithLabelInterface;

ComponentHorizontalWithLabel.displayName = 'ComponentHorizontalWithLabel';

export default ComponentHorizontalWithLabel;
