import React from 'react';
import { ButtonProps, Icon } from '@mis/sushi-tailwind-react';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

export const CompanySaveButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button.Primary icon={<Icon name="save" />} data-testid="btn-save-company" type="submit" {...props}>
      {t('button:save')}
    </Button.Primary>
  );
};
