export type RequestType = { menu: string; children?: RequestType[]; urlId?: number | null };

export const flattenDeepestLevelNames = (menu: RequestType[]): string[] => {
  const names: string[] = [];
  const traverse = (menuItem: RequestType[], parentName = '') => {
    for (const item of menuItem) {
      names.push(item.menu.replace(parentName, ''));
      if (item.children && item.children.length > 0) {
        traverse(item.children, item.menu + '_');
      }
    }
  };

  traverse(menu, '');
  return names;
};
