import React, { Fragment, useEffect } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

export default function TriggerFormInitially<
  TFieldValues extends FieldValues = FieldValues,
  TContext = AnyValue,
  TTransformedValues extends FieldValues | undefined = undefined
>({ trigger, handleSubmit }: UseFormReturn<TFieldValues, TContext, TTransformedValues>): React.ReactElement {
  useEffect(() => {
    handleSubmit(
      (() => undefined) as unknown as TTransformedValues extends undefined
        ? SubmitHandler<TFieldValues>
        : TTransformedValues extends FieldValues
        ? SubmitHandler<TTransformedValues>
        : never
    )();
  }, [handleSubmit, trigger]);

  return <Fragment />;
}
