import { ProfileService } from '@core/services/profile.service';
import { TokenService } from '@core/services/token.service';
import { Profile } from '@model/profile';
import { firstValueFrom, forkJoin } from 'rxjs';

export interface DefaultData {
  profile: Profile;
  passwordExpired: boolean;
}

export default async function getDefaultData(): Promise<Partial<DefaultData>> {
  const jwt = TokenService.getToken();
  const data$ = forkJoin({
    profile: ProfileService.getProfile(),
    passwordExpired: ProfileService.getChangePassword(jwt as string),
    permission: ProfileService.getMainMenu(),
    // permission: of([
    //   { name: 'HOME' },
    //   {
    //     name: 'DETAIL_OF_COMPANY',
    //     children: [
    //       { name: 'DETAIL_OF_COMPANY_COMPANY_INFO' },
    //       { name: 'DETAIL_OF_COMPANY_AUTHORIZED_SIGNATURE' },
    //       { name: 'DETAIL_OF_COMPANY_DOCUMENT_USER_LIST' },
    //     ],
    //   },
    //   {
    //     name: 'USER_INFO',
    //     children: [
    //       { name: 'USER_INFO_APPROVE_USER' },
    //       { name: 'USER_INFO_ADD_USER' },
    //       { name: 'USER_INFO_ADD_USER_SEC' },
    //       { name: 'USER_INFO_ADD_USER_SET' },
    //       { name: 'USER_INFO_USER_LIST' },
    //       { name: 'USER_INFO_USER_LIST_SEC' },
    //       { name: 'USER_INFO_USER_LIST_SET' },
    //     ],
    //   },
    //   {
    //     name: 'REPORTS_AND_STATISTICS',
    //     children: [{ name: 'REPORTS_AND_STATISTICS_EQUITY_INSTRUMENTS_INFO' }, { name: 'REPORTS_AND_STATISTICS_CORPORATE_CONTACT_INFO' }],
    //   },
    //   {
    //     name: 'MANAGEMENT',
    //     children: [
    //       { name: 'MANAGEMENT_AUDITOR' },
    //       { name: 'MANAGEMENT_RESERVE_SPECIAL_SYMBOL' },
    //       { name: 'MANAGEMENT_COMMON_SYMBOL_LIST' },
    //       { name: 'MANAGEMENT_TRUSTEE' },
    //       { name: 'MANAGEMENT_COMPANY' },
    //       { name: 'MANAGEMENT_TEMPLATE_OFFERING_DOCUMENT_AND_PO' },
    //       { name: 'MANAGEMENT_TEMPLATE_FILING' },
    //     ],
    //   },
    //   { name: 'CONTACT_MANAGEMENT' },
    //   { name: 'USER_MANUAL_AND_RELATED_DOCUMENTS' },
    // ] as MenuItem[]),
  });
  return await firstValueFrom(data$);
}
