import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    keySeparator: '.',
    lng: 'th',
    fallbackLng: 'th',
    preload: ['th', 'en'],
    ns: ['translation', 'button', 'error', 'label', 'title'],
    defaultNS: 'translation',
    backend: { loadPath: '/digitalipo/locales/{{lng}}/{{ns}}.json' },
    debug: process.env.NODE_ENV !== 'production',
  });

export default i18n;
