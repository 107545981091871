export class URLStoreService {
  private static redirectKey = 'redirect';

  public static getRedirectURL(): string | null {
    return sessionStorage.getItem(this.redirectKey) || null;
  }

  public static setRedirectURL(token: string): void {
    sessionStorage.setItem(this.redirectKey, token || '');
  }

  public static clearRedirectURL(): void {
    sessionStorage.removeItem(this.redirectKey);
  }
}
