import React from 'react';
import { Controller, FieldPath, FieldValues, PathValue } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption } from '@mis/sushi-tailwind-react';
import { useFormContextWithAdditionalProp } from '@components/form-hook-wrapper/FormHookWrapper';
import InputDropdown from '@components/input/input-dropdown/InputDropdown';

export type InputDropdownControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Dropdown> & {
    viewOnly?: boolean;
    overModal?: boolean;
  };

const InputDropdownController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  rules,
  placeholder,
  options = [],
  viewOnly,
  overModal,
  allowClear = true,
  ...props
}: InputDropdownControllerProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control, setValue, displayErrorMessage } = useFormContextWithAdditionalProp<TFieldValues>();

  const onChangeValue = (value: PathValue<TFieldValues, TName>, option: DropdownOption | DropdownOption[]) => {
    setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    props.onChange?.(value, option);
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <InputDropdown
          {...field}
          {...props}
          value={props.value || field.value || undefined}
          onChange={onChangeValue}
          options={options}
          notFoundContent={t('translation:no_data')}
          placeholder={placeholder || t('label:other.please_select')}
          status={displayErrorMessage && error ? 'error' : undefined}
          message={displayErrorMessage && error ? t(error?.message as string) : undefined}
          allowClear={allowClear}
          viewOnly={viewOnly}
          overModal={overModal}
        />
      )}
    />
  );
};

export default InputDropdownController;
