import { Input } from '@mis/sushi-tailwind-react';
import React from 'react';

export const TextDisplay: React.FC<Omit<React.ComponentProps<typeof Input>, 'value'> & { value: Nullable<string> }> = ({
  name,
  value,
  textAlign,
  placeholder,
}) => {
  return (
    <Input textAlign={textAlign} id={name} name={name} data-testid={name} value={value || ''} placeholder={placeholder || ''} viewOnly />
  );
};
