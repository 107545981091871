import Button from '@components/button';
import CheckboxController from '@components/form-hook-input/checkbox/Checkbox';
import FormHookWrapper from '@components/form-hook-wrapper/FormHookWrapper';
import InputDropdownForm from '@components/form-with-label-horizontal/input-dropdown-form/InputDropdownForm';
import InputStringForm from '@components/form-with-label-horizontal/input-string-form/InputStringForm';
import InputTextareaForm from '@components/form-with-label-horizontal/input-textarea-form/InputTextareaForm';
import RadioGroupForm from '@components/form-with-label-horizontal/radio-group-form/RadioGroupForm';
import { Profile } from '@model/profile';
import { FeedbackService } from '@core/services/feedback.service';
import { TypeOfCommentEnum } from '@model/enum/feedback.enum';
import { FeedbackFormType } from '@model/form/feedback.form';
import { FormProps } from '@model/form/form-properties';
import { FeedbackDropdownTopicResponse } from '@model/response/feedback.response';
import React from 'react';
import { finalize } from 'rxjs';
import * as yup from 'yup';
import ComponentHorizontalWithLabel from '@components/component-with-label/ComponentHorizontalWithLabel';
import InputUploadController from '@components/form-hook-input/input-upload/InputUpload';
import { useTranslation } from 'react-i18next';

interface FeedbackFormProps extends FormProps<FeedbackFormType> {
  profile: Profile;
  onCancel?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const topicOther = 'อื่น ๆ (โปรดระบุ)';

export const feedbackFormInitialValues: FeedbackFormType = {
  anonymous: false,
  fullName: '',
  phoneNo: '',
  email: '',
  position: '',
  company: '',
  topic: null,
  subTopic: null,
  topicOther: '',
  subTopicOther: '',
  typeOfComment: null,
  comment: '',
  attachFile: null,
};

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onCancel, onSubmit, profile }) => {
  const { t } = useTranslation();
  const [dropdownTopic, setDropdownTopic] = React.useState<FeedbackDropdownTopicResponse[]>();
  const [loadingDropdown, setLoadingDropdown] = React.useState(false);

  const schema = React.useMemo(
    () =>
      yup.object().shape({
        topic: yup.string().nullable().required(''),
        topicOther: yup.string().when('topic', {
          is: (val: string) => val === topicOther,
          then: yup.string().required(''),
          otherwise: yup.string(),
        }),
        subTopic: yup
          .string()
          .nullable()
          .when('topic', {
            is: (val: string) => {
              const topic = dropdownTopic?.find((item) => item.value === val);
              if (!topic) return false;
              const subDropdown = topic?.subDropdown;
              return subDropdown && subDropdown?.length > 0;
            },
            then: yup.string().nullable().required(''),
            otherwise: yup.string().nullable(),
          }),
        subTopicOther: yup.string().when('subTopic', {
          is: (val: string) => val === topicOther,
          then: yup.string().required(''),
          otherwise: yup.string(),
        }),
        email: yup.string().nullable().email('other.invalid_email_format'),
        typeOfComment: yup.string().nullable().required(''),
        comment: yup.string().required(''),
      }),
    [dropdownTopic]
  );

  const initialValues = React.useMemo<FeedbackFormType>(
    () => ({
      ...feedbackFormInitialValues,
      fullName: profile?.fullNameTh || '',
      phoneNo: profile?.phoneNo || '',
      position: profile?.position || '',
      company: profile?.company.nameTh || '',
      email: profile?.username || '',
    }),
    [profile?.company.nameTh, profile?.position, profile?.fullNameTh, profile?.phoneNo, profile?.username]
  );

  React.useEffect(() => {
    setLoadingDropdown(true);
    FeedbackService.getDropdownFeedbackTopic()
      .pipe(finalize(() => setLoadingDropdown(false)))
      .subscribe(setDropdownTopic);
  }, []);

  const mainTopicDropdownOptions = React.useMemo(
    () => dropdownTopic?.map((item) => ({ label: item.label, value: item.value })),
    [dropdownTopic]
  );

  return (
    <FormHookWrapper defaultValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ watch, setValue, formState: { isSubmitting } }) => {
        const [anonymous, topic, subTopic] = watch(['anonymous', 'topic', 'subTopic']);
        const onChangeAnonymous = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            setValue('fullName', '');
            setValue('phoneNo', '');
            setValue('email', null);
            setValue('position', '');
            setValue('company', '');
          } else {
            setValue('fullName', profile?.fullNameTh || '');
            setValue('phoneNo', profile?.phoneNo || '');
            setValue('email', profile?.username || '');
            setValue('position', profile?.position || '');
            setValue('company', profile?.company.nameTh || '');
          }
        };

        const onChangeTopic = (value: Nullable<string>) => {
          setValue('topic', value || null, { shouldValidate: true });
          setValue('topicOther', '');
          setValue('subTopic', null);
          setValue('subTopicOther', '');
        };

        const profilePlaceholderHandler = (placeholder: string) => (anonymous ? 'ไม่ระบุ' : placeholder);
        const subTopicDropdownOptions = dropdownTopic
          ?.find((item) => item.value === topic)
          ?.subDropdown?.map((item) => ({ label: item.label, value: item.value }));

        return (
          <div className="flex w-full max-w-[800px] flex-col px-8 pt-0 text-black">
            <div className="mb-6 text-2xl text-primary-900">
              แสดงความคิดเห็นที่เกี่ยวข้องกับการระดมทุน และการเข้าจดทะเบียนในตลาดหลักทรัพย์ฯ
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <b>ข้อมูลผู้ให้ความคิดเห็น</b>
                <CheckboxController name="anonymous" onChange={onChangeAnonymous}>
                  ไม่ระบุตัวตน (Anonymous)
                </CheckboxController>
              </div>
              <InputStringForm
                disabled={anonymous || isSubmitting}
                name="fullName"
                label="ชื่อ - นามสกุล"
                placeholder={profilePlaceholderHandler('กรุณาระบุชื่อ - นามสกุล')}
                maxLength={250}
              />
              <InputStringForm
                disabled={anonymous || isSubmitting}
                name="phoneNo"
                label="เบอร์โทรติดต่อ"
                placeholder={profilePlaceholderHandler('กรุณาระบุเบอร์โทรติดต่อ')}
                maxLength={250}
              />
              <InputStringForm
                disabled={anonymous || isSubmitting}
                name="email"
                label="อีเมล"
                placeholder={profilePlaceholderHandler('กรุณาระบุอีเมล')}
                maxLength={250}
              />
              <InputStringForm
                disabled={anonymous || isSubmitting}
                name="position"
                label="ตำแหน่ง"
                placeholder={profilePlaceholderHandler('กรุณาระบุตำแหน่ง')}
                maxLength={250}
              />
              <InputStringForm
                disabled
                name="company"
                label="บริษัท"
                placeholder={profilePlaceholderHandler('กรุณาระบุบริษัท')}
                maxLength={250}
              />
              <div className="flex justify-between">
                <b>ข้อมูลความคิดเห็น</b>
              </div>
              <InputDropdownForm
                overModal
                name="topic"
                disabled={isSubmitting || loadingDropdown}
                label="หัวข้อหลัก"
                placeholder="กรุณาเลือกหัวข้อหลักประเด็นความคิดเห็น"
                options={mainTopicDropdownOptions}
                onChange={onChangeTopic}
              />
              {subTopicDropdownOptions && (
                <InputDropdownForm
                  overModal
                  name="subTopic"
                  disabled={isSubmitting || loadingDropdown}
                  label="หัวข้อย่อย"
                  placeholder="กรุณาเลือกหัวข้อย่อยประเด็นความคิดเห็น"
                  options={subTopicDropdownOptions}
                />
              )}
              {topic === topicOther && (
                <InputStringForm
                  disabled={isSubmitting}
                  name="topicOther"
                  placeholder="กรุณากรอกหัวข้อหลักประเด็นความคิดเห็นอื่น ๆ"
                  maxLength={250}
                />
              )}
              {subTopic === topicOther && (
                <InputStringForm
                  disabled={isSubmitting}
                  name="subTopicOther"
                  placeholder="กรุณากรอกหัวข้อย่อยประเด็นความคิดเห็นอื่น ๆ"
                  maxLength={250}
                />
              )}
              <RadioGroupForm
                name="typeOfComment"
                label="ประเภทความคิดเห็น"
                disabled={isSubmitting}
                items={[
                  { label: 'ข้อเสนอแนะ', value: TypeOfCommentEnum.RECOMMENDATION },
                  { label: 'ชมเชย', value: TypeOfCommentEnum.APPRECIATION },
                  { label: 'ข้อร้องเรียน', value: TypeOfCommentEnum.COMPLAINT },
                ]}
              />
              <InputTextareaForm
                disabled={isSubmitting}
                name="comment"
                label="ความคิดเห็น"
                placeholder="กรุณากรอกความคิดเห็น..."
                maxLength={1000}
              />
              <ComponentHorizontalWithLabel label="เอกสารแนบ" labelTop>
                <InputUploadController
                  acceptType=".jpg,.jpeg,.png,.pptx,.xlsx,.doc,.pdf"
                  disabled={isSubmitting}
                  isUploading={isSubmitting}
                  maxFileSizeNumber={10000000}
                  maxSizeMessage=""
                  acceptLabel="รองรับไฟล์สูงสุด 10 MB ( .jpg .jpeg .png .pptx .xlsx .doc .pdf )"
                  placeholder="กรุณาเลือกไฟล์"
                  name="attachFile"
                  style={{ maxWidth: '500px', width: '100%' }}
                />
              </ComponentHorizontalWithLabel>
            </div>
            <div className="flex flex-row justify-end gap-4 py-4 text-black">
              <Button.Secondary onClick={onCancel}>{t('button:cancel')}</Button.Secondary>
              <Button.Primary type="submit">{t('button:submit')}</Button.Primary>
            </div>
          </div>
        );
      }}
    </FormHookWrapper>
  );
};

export default FeedbackForm;
