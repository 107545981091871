export class DisclaimerService {
  private static contactKey = 'contact';

  public static getContact(): boolean {
    return localStorage.getItem(this.contactKey) === 'true';
  }

  public static setContact(contact: boolean): void {
    localStorage.setItem(this.contactKey, `${contact}`);
  }
}
