import React from 'react';
import { ButtonProps, Icon } from '@mis/sushi-tailwind-react';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

export const CompanyPreviewButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button.Primary icon={<Icon name="search" />} {...props}>
      {t('button:preview')}
    </Button.Primary>
  );
};
