import React from 'react';
import classNames from 'classnames';
import { Radio } from '@mis/sushi-tailwind-react';
import { useTranslation } from 'react-i18next';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { TextDisplay } from '@components/text-display/TextDisplay';
import ErrorMessage from '@components/error-message/ErrorMessage';
import { RadioOption } from '@model/radio';
import { useFormContextWithAdditionalProp } from '@components/form-hook-wrapper/FormHookWrapper';

export type RadioGroupControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Radio> & {
    items: RadioOption[];
    viewOnly?: boolean;
    verticalRadio?: boolean;
  };

const RadioGroupController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  rules,
  items,
  viewOnly,
  onChange,
  verticalRadio,
  ...props
}: RadioGroupControllerProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control, displayErrorMessage } = useFormContextWithAdditionalProp<TFieldValues>();

  const onRadioChange = (onchange: (...event: AnyValue[]) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onchange(e);
    onChange && onChange(e);
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        if (viewOnly) {
          const displayValue = items.find((i) => i.value === field.value)?.label;
          return <TextDisplay name={name} value={displayValue} />;
        }
        return (
          <div className="flex flex-col">
            <div className={classNames('flex flex-row flex-wrap gap-2', { 'flex-col': verticalRadio })}>
              {items.map(({ label, value }) => {
                const selected = String(props.value) === String(value) || String(field.value) === String(value);
                return (
                  <div key={value} className={classNames({ 'text-gray-100': props.disabled })}>
                    <Radio
                      {...field}
                      {...props}
                      onChange={onRadioChange(field.onChange)}
                      className={classNames({
                        'border-danger-900': !!error && displayErrorMessage,
                      })}
                      value={value}
                      data-testid={`${value}Radio`}
                      checked={selected}
                    >
                      {label}
                    </Radio>
                  </div>
                );
              })}
            </div>
            {error && displayErrorMessage && (
              <ErrorMessage status="error" message={t(error?.message as string)} className="text-xs text-danger-900" />
            )}
          </div>
        );
      }}
    />
  );
};

export default RadioGroupController;
