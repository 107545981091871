import { useLiveChatDispatch, useLiveChatState } from '@context/LiveChat.context';
import { useProfileState } from '@context/Profile.context';
import { env } from '@core/env';
import { TokenService } from '@core/services/token.service';
import React, { memo, useEffect, useState } from 'react';

const LiveChat: React.FC = () => {
  const { profile } = useProfileState();
  const { initial } = useLiveChatState();
  const [scriptInjectedSuccessful, setScriptInjectedSuccessful] = useState(false);
  const dispatch = useLiveChatDispatch();

  useEffect(() => {
    if (scriptInjectedSuccessful) {
      return;
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = env.liveChatUrl;

    script.id = 'zwiz-web-chat-sdk';
    script.setAttribute('data-client-id', 'dipo');
    script.setAttribute('data-client-secret', env.liveChatClientSecret);
    script.setAttribute('data-env', env.liveChatEnvName);

    script.onload = () => {
      setScriptInjectedSuccessful(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile && scriptInjectedSuccessful && !initial) {
      window.ZWIZ.initWebChat();
      dispatch({ type: 'SET_INITIAL', payload: true });
    }
  }, [dispatch, scriptInjectedSuccessful, initial, profile]);

  useEffect(() => {
    const handleEventRequestAuthToken = () => {
      TokenService.getLiveChatToken().subscribe((token) => {
        window.ZWIZ.handleUpdateToken({ token: `Bearer ${token.token}` });
      });
    };

    if (initial) {
      window.addEventListener('eventRequestAuthToken', handleEventRequestAuthToken);
    }

    return () => {
      if (initial) {
        window.removeEventListener('eventRequestAuthToken', handleEventRequestAuthToken);
      }
    };
  }, [initial]);
  return null;
};

export default memo(LiveChat);
